import React, { FC } from 'react';
import styled from 'styled-components';

import { Text } from 'components';
import theme, { Typography } from 'utils/theme';
import CircleTick from 'assets/icons/payments/circle-tick.svg';
import { smMobile, tablet, useQuery } from 'styles/breakpoints';

interface ChecklistItemProps {
  label: string;
  marginBot?: boolean;
  circleSize?: number;
  labelType?: Typography;
  circleMarginRight?: number;
  circleColor?: string;
  fontSize?: number;
  margin?: string;
  width?: string;
}

const GoalContainer = styled.div<{
  marginBot?: boolean;
  margin?: string;
  $width;
}>`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: ${({ margin }) => (margin ? margin : '')};
  margin-bottom: ${({ marginBot }) => (marginBot ? '1rem' : 0)};
  width: ${({ $width }) => ($width ? `${$width}px` : '356px')};
  @media ${tablet} {
    margin-bottom: ${({ marginBot }) => (marginBot ? '1.5rem' : 0)};
  }
  @media ${smMobile} {
    max-width: ${({ $width }) => ($width ? `${$width}px` : '288px')};
  }
`;

const Goal = styled(Text)``;

const CircleTickStyled = styled(CircleTick)<{
  circleSize?: number;
  circleMarginRight?: number;
}>`
  margin-right: ${({ circleMarginRight }) =>
    circleMarginRight ? circleMarginRight + 'rem' : '0.25rem'};
  max-width: ${({ circleSize }) =>
    circleSize ? circleSize + 'rem' : '1.5rem'};
  max-height: ${({ circleSize }) =>
    circleSize ? circleSize + 'rem' : '1.5rem'};
  height: 100%;
  width: 100%;
  @media ${tablet} {
    max-width: ${({ circleSize }) =>
      circleSize ? circleSize + 'rem' : '1.5rem'};
    max-height: ${({ circleSize }) =>
      circleSize ? circleSize + 'rem' : '1.5rem'};
  }
`;
const ChecklistItem: FC<ChecklistItemProps> = ({
  label,
  marginBot,
  circleSize,
  labelType,
  circleMarginRight,
  circleColor,
  fontSize,
  margin,
  width,
}) => {
  const { isTablet } = useQuery();

  return (
    <GoalContainer marginBot={marginBot} margin={margin} $width={width}>
      <CircleTickStyled
        fill={circleColor || theme.colors.green}
        circleSize={circleSize}
        circleMarginRight={circleMarginRight}
      />
      <Goal type={isTablet ? 'bodyM' : 'body'}>{label}</Goal>
    </GoalContainer>
  );
};

export default ChecklistItem;
