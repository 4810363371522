import React from 'react';
import type { FC } from 'react';
import styled from 'styled-components';
import PrimaryButton from 'components/PrimaryButton';

interface ButtonProps {
  onClick: () => void;
}

const Button = styled(PrimaryButton).attrs({
  cornerVariant: 'round',
})`
  width: 15rem;
  margin-top: 3.17rem;
`;

const SecurePaymentButton: FC<ButtonProps> = ({
  onClick,
  children = 'Start now',
}) => <Button onClick={onClick}>{children}</Button>;

export default React.memo(SecurePaymentButton);
