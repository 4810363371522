import React, { FC } from 'react';
import styled from 'styled-components';
import { DynamicImage, Text } from 'components';
import { tablet, useQuery } from 'styles/breakpoints';
import TickIcon from 'assets/icons/tick-circle.svg';
import theme from 'utils/theme';

interface VerticalListWithImgProps {
  imgUrl: string;
  list: { text: string; title?: string; icon?: string | null }[];
  title: string;
}

const OuterContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

const Container = styled.div<{ $isVertical?: boolean }>`
  display: flex;
  align-items: center;
  jusitfy-content: center;
  flex-direction: column;
  gap: 2rem;
  width: 100%;
  max-width: 1040px;
  padding: 4rem 2rem;

  @media ${tablet} {
    flex-direction: column;
    gap: 1.5rem;
    padding: 3rem 1rem;
  }
`;
const TextWrapper = styled.div<{ $isVertical?: boolean }>`
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 100%;
  justify-content: center;
`;

const Image = styled(DynamicImage)`
  flex: 1;
  min-height: 29.744375rem;
  width: 100%;
  height: 100%;
  max-width: 50%;
  @media ${tablet} {
    max-width: 100%;
    min-height: unset;
  }
`;

const ListContainer = styled.div<{ $isVertical?: boolean }>`
  display: flex;
  flex-direction: row;
  flex: inherit;
  max-width: 900px;
  gap: 1rem;
  @media ${tablet} {
    flex-direction: column;
    margin-left: 0;
    margin-top: 0.5rem;
    max-width: none;
  }
`;

const ListItemContainer = styled.div<{ $isVertical?: boolean }>`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 1rem;
  width: 280px;
  @media ${tablet} {
    align-items: flex-start;
    flex-direction: row;
    width: 100%;
  }
`;

const TickIconStyled = styled(TickIcon)`
  height: 2rem;
  width: 2rem;
  @media ${tablet} {
    height: 1.5rem;
    width: 1.5rem;
  }
`;

const IconContainer = styled.div`
  height: 2rem;
  width: 2rem;
  @media ${tablet} {
    height: 1.5rem;
    width: 1.5rem;
  }
`;

const ImageContainer = styled.div``;

const StyledDynamicImage = styled(DynamicImage)`
  object-fit: contain;
  width: 3rem;
  height: 3rem;
`;

const VerticalListWithImg: FC<VerticalListWithImgProps> = ({
  imgUrl,
  list,
  title,
}) => {
  const { isTablet } = useQuery();

  const renderListItem = (
    item: { text: string; title?: string; icon?: string | null },
    index: number,
  ) => (
    <ListItemContainer key={`${title}-${index}`}>
      {item?.icon ? (
        <ImageContainer>
          <StyledDynamicImage src={item.icon} alt={item?.text || 'icon'} />
        </ImageContainer>
      ) : (
        <IconContainer>
          <TickIconStyled fill="#2FBE8F" />
        </IconContainer>
      )}
      <TextWrapper>
        {item?.title && (
          <Text
            type={isTablet ? 'h2600' : 'h1'}
            textAlign={isTablet ? 'left' : 'center'}
            dangerouslySetInnerHTML={{ __html: item?.title }}
          />
        )}
        <Text
          type="captionM"
          textAlign={isTablet ? 'left' : 'center'}
          dangerouslySetInnerHTML={{ __html: item.text }}
        />
      </TextWrapper>
    </ListItemContainer>
  );
  return (
    <OuterContainer>
      <Container>
        {title && <Text type={isTablet ? 'h2' : 'h1'}>{title}</Text>}

        <Image src={imgUrl} alt="" />

        <ListContainer>{list.map(renderListItem)}</ListContainer>
      </Container>
    </OuterContainer>
  );
};

export default VerticalListWithImg;
