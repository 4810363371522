import React from 'react';
import type { FC } from 'react';

import styled from 'styled-components';

import { tablet, useQuery } from 'styles/breakpoints';
import { Text } from 'components';

import StartNowButton from '../StartNowButton';

import IconWithCaption from '../IconWithCaption';
import theme, { Color } from 'utils/theme';

interface ListItem {
  img: string;
  title: string;
  description?: string;
}

interface IconWithCaptionProps {
  onClick?: () => void;
  bgColor?: string;
  textColor?: Color;
  data: {
    title?: string;
    overline?: string;
    iconSize?: string;
    cardWidth?: string;
    smallerTitle?: boolean;
    startBtnTitle?: string;
    list: ListItem[];
  };
}

const Container = styled.div<{ bgColor }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 3rem;
  padding: 4rem 1rem;
  background-color: ${({ bgColor }) => bgColor};
  @media ${tablet} {
    padding: 3rem 1rem;
    gap: 2rem;
  }
`;
const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 770px;
  gap: 1rem;
  @media ${tablet} {
    gap: 0.75rem;
  }
`;

const ListItems = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  max-width: 896px;
  width: 100%;
  gap: 1rem;
`;

const Card = styled.div<{ $cardWidth?: string }>`
  display: flex;
  padding: 1.5rem 1rem;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  width: 100%;
  max-width: ${({ $cardWidth }) => $cardWidth || '330px'};
  border-radius: 16px;
  background: #fff;
  box-shadow: 0px 4px 32px 0px rgba(0, 0, 0, 0.06);
  @media ${tablet} {
    max-width: 100%;
    width: 100%;
  }
`;

const Title = styled(Text)`
  max-width: 1000px;
  text-align: center;
`;

const IconWithCaptionCardList: FC<IconWithCaptionProps> = ({
  data,
  onClick,
  bgColor = '#FFFBF8',
  textColor = 'dark100',
}) => {
  const { isTablet } = useQuery();
  return (
    <Container bgColor={bgColor}>
      <TextWrapper>
        <Title
          type={isTablet ? (data.smallerTitle ? 'h3' : 'h2') : 'h1'}
          color={textColor}
        >
          {data.title}
        </Title>

        {data.overline && (
          <Title color={textColor} type="bodyM">
            {data.overline}
          </Title>
        )}
      </TextWrapper>

      <ListItems>
        {data.list.map((item, index) => (
          <Card key={`${item.title}-${index}`} $cardWidth={data.cardWidth}>
            <IconWithCaption
              item={item}
              titleType={isTablet ? 'h4' : 'h4'}
              iconSize={data?.iconSize || '32px'}
              width="100%"
            />
          </Card>
        ))}
      </ListItems>

      {data?.startBtnTitle && (
        <StartNowButton onClick={onClick}>{data.startBtnTitle}</StartNowButton>
      )}
    </Container>
  );
};
export default React.memo(IconWithCaptionCardList);
