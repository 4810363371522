import React from 'react';
import type { FC } from 'react';
import styled from 'styled-components';

import { DynamicImage, Text } from 'components';

import { tablet, useQuery } from 'styles/breakpoints';

import img from 'assets/images/funnel/ad5/results/section6/bgImage_des.png';

interface OurPromiseProps {
  title?: string;
  text?: string;
  bottomText?: string;
  bgImageDes?: string;
  bgImageMob?: string;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 4.5rem 2rem;
  gap: 1rem;
  @media ${tablet} {
    padding: 2.25rem 1rem;
  }
`;

const Card = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  border-radius: 16px;
  padding: 3rem 6rem;
  max-width: 750px;
  // background-image: ${`url(${img})`};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  object-fit: cover;
  @media ${tablet} {
    padding: 2.25rem 1rem;
    gap: 1rem;
  }
`;

const Title = styled(Text).attrs({
  color: 'dark100',
})`
  text-align: center;
`;

const StyledDynamicImage = styled(DynamicImage)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important;
  min-width: 100%;
  z-index: -1;
  border-radius: 1rem;
  @media ${tablet} {
    height: 100%;
  }
`;

const StyledImg = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  min-width: 100%;
  height: 100%;
  z-index: -1;
  opacity: 1;
  border-radius: 1rem;
  @media ${tablet} {
    height: 100%;
  }
`;

const OurPromise: FC<OurPromiseProps> = ({
  title,
  text,
  bgImageDes,
  bgImageMob,
  bottomText,
}) => {
  const { isTablet, isMobile } = useQuery();

  return (
    <Container>
      <Card>
        {bgImageDes && bgImageMob && (
          <StyledDynamicImage
            src={isMobile ? bgImageMob : bgImageDes}
            height="100%"
            width="100%"
            objectFit="fill"
            alt={'background'}
          />
        )}

        <Title type={isTablet ? 'h2' : 'h1'}>{title}</Title>

        {text && (
          <Text
            type="bodyM"
            dangerouslySetInnerHTML={{
              __html: text,
            }}
          />
        )}

        {bottomText && (
          <Text
            type="body700"
            dangerouslySetInnerHTML={{
              __html: bottomText,
            }}
          />
        )}
      </Card>
    </Container>
  );
};

export default OurPromise;
