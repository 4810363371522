import React from 'react';
import type { FC, ReactNode } from 'react';
import styled from 'styled-components';
import { tablet } from 'styles/breakpoints';

interface IconContainerProps {
  IconComponent: ReactNode;
  children: ReactNode;
  TitleComponent?: ReactNode;
}

const AppImageContainer = styled.div`
  width: 32.62rem;
  margin-right: 1rem;

  @media ${tablet} {
    width: 100%;
    max-width: 32.62rem;
    margin: 0 0 2rem 0;
  }
`;

const ListWrap = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  margin-top: 3.42rem;
  justify-content: space-between;

  @media ${tablet} {
    flex-direction: column;
    margin-top: 2rem;
  }
`;

const ListContainer = styled.div`
  width: 32.62rem;
  > div:nth-child(1n):not(:last-child) {
    margin-bottom: 1.5rem;
  }
  margin-left: 3rem;

  @media ${tablet} {
    margin-left: 0rem;
    width: 100%;
  }
`;

const IconContainer: FC<IconContainerProps> = ({
  IconComponent,
  TitleComponent,
  children,
}) => (
  <ListWrap>
    <AppImageContainer>{IconComponent}</AppImageContainer>
    {TitleComponent}
    <ListContainer>{children}</ListContainer>
  </ListWrap>
);

export default IconContainer;
