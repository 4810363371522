import React from 'react';
import type { FC } from 'react';
import styled from 'styled-components';
import { DynamicImage, ImageWrapper, Text } from 'components';
import { mobile, tablet, useQuery } from 'styles/breakpoints';
import Button from './SecurePaymentButton';
import IconListContainer from './IconContainer';

interface DescriptionProps {
  onClick: () => void;
  title: string;
  listData: { title: string; description: string; img: string }[];
  submitBtnTitle: string;
  alt: string;
  appImg?: string;
  noIcon?: boolean;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Title = styled(Text).attrs({
  color: 'dark100',
})`
  margin-bottom: 3rem;

  @media ${mobile} {
    margin-bottom: 2rem;
  }

  span {
    text-decoration: line-through;
    color: ${({ theme }) => theme.colors.dark60};

    font-family: ${({ theme }) => theme.typography.count.fontFamily};
    font-size: ${({ theme }) => theme.typography.count.fontSize}rem;
    line-height: ${({ theme }) => theme.typography.count.lineHeight}rem;

    @media ${mobile} {
      font-family: ${({ theme }) => theme.typography.countS.fontFamily};
      font-size: ${({ theme }) => theme.typography.countS.fontSize}rem;
      line-height: ${({ theme }) => theme.typography.countS.lineHeight}rem;
    }
  }
`;

const ListItem = styled.div`
  display: flex;
  @media ${mobile} {
    min-height: 3.8125rem;
  }
`;

const IconContainer = styled.div`
  width: 3rem;
  height: 3rem;
  margin-right: 1.5rem;
`;

const ListItemContent = styled.div``;

const ListItemTitle = styled(Text).attrs({
  color: 'dark100',
})`
  margin-bottom: 3rem;
  @media ${tablet} {
    margin-bottom: 2rem;
  }
`;

const ListItemText = styled(Text).attrs({
  type: 'bodyM',
  color: 'dark100',
})`
  -webkit-text-size-adjust: 100%;
`;

const GridListContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, auto);
  column-gap: 6.24rem;
  row-gap: 1.5rem;

  @media ${tablet} {
    grid-template-columns: repeat(1, auto);
    column-gap: 0rem;
  }
`;

const IconListContainerWrap = styled.div`
  margin-top: -3.42rem;

  @media ${tablet} {
    margin-top: -2rem;
  }
`;

const Description: FC<DescriptionProps> = ({
  onClick,
  noIcon,
  appImg,
  listData,
  submitBtnTitle,
  title,
  alt,
}) => {
  const { isTablet } = useQuery();

  const renderListItem = (
    item: { title: string; description: string; img: string },
    index: number,
  ) => {
    const key = `description-item-${index}`;

    return (
      <ListItem key={key}>
        <IconContainer>
          <DynamicImage
            src={item.img}
            alt={item.title}
            height="3rem"
            width="3rem"
          />
        </IconContainer>
        <ListItemContent>
          <ListItemTitle type={isTablet ? 'h2600' : 'h1'}>
            {item?.title}
          </ListItemTitle>

          <ListItemText>{item?.description}</ListItemText>
        </ListItemContent>
      </ListItem>
    );
  };

  const renderTitle = () => {
    if (!title) {
      return;
    }
    return (
      <Title
        dangerouslySetInnerHTML={{
          __html: title,
        }}
        type={isTablet ? 'countS' : 'count'}
      />
    );
  };

  return (
    <Container>
      {!isTablet && renderTitle()}
      {noIcon ? (
        <GridListContainer>{listData?.map(renderListItem)}</GridListContainer>
      ) : (
        <IconListContainerWrap>
          {appImg ? (
            <IconListContainer
              TitleComponent={isTablet ? renderTitle() : null}
              IconComponent={
                <ImageWrapper>
                  <DynamicImage src={appImg} alt={alt} />
                </ImageWrapper>
              }
            >
              {listData?.map(renderListItem)}
            </IconListContainer>
          ) : null}
        </IconListContainerWrap>
      )}
      {submitBtnTitle && <Button onClick={onClick}>{submitBtnTitle}</Button>}
    </Container>
  );
};

export default Description;
