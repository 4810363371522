import React, { useCallback } from 'react';
import type { FC } from 'react';
import styled from 'styled-components';
import { tablet, useQuery } from 'styles/breakpoints';
import { DynamicImage, Text } from 'components';

interface ListItem {
  img: string;
  title: string;
  text: string;
}

interface IconListProps {
  data: ListItem[];
}

const Container = styled.div`
  display: flex;
  justify-content: space-between;

  > div:nth-child(1n):not(:last-child) {
    margin-right: 1rem;
  }

  @media ${tablet} {
    flex-direction: column;

    > div:nth-child(1n):not(:last-child) {
      margin: 0 0 1.75rem 0;
    }
  }
`;

const ItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 15.56rem;

  @media ${tablet} {
    flex-direction: row;
    width: auto;
  }
`;

const ImageContainer = styled.div``;

const StyledDynamicImage = styled(DynamicImage)`
  object-fit: contain;
  margin-bottom: 1.25rem;
  width: 3rem;
  height: 3rem;

  @media ${tablet} {
    margin: 0 1.25rem 0 0;
  }
`;

const TextStyled = styled(Text).attrs({
  color: 'dark80',
})`
  margin-top: 0.5rem;
  -webkit-text-size-adjust: 100%;
  @media ${tablet} {
    margin-top: 0.5rem;
  }
`;

const IconList: FC<IconListProps> = ({ data }) => {
  const { isMobile } = useQuery();

  const renderItem = useCallback((item: ListItem, index: number) => {
    const key = `description-2-item-${index}`;
    return (
      <ItemContainer key={key}>
        <ImageContainer>
          <StyledDynamicImage src={item.img} alt={item.title} />
        </ImageContainer>
        <div>
          <Text type="body500" color="dark80">
            {item.title}
          </Text>
          <TextStyled fontSize={isMobile ? 0.75 : 0.875}>
            {item.text}
          </TextStyled>
        </div>
      </ItemContainer>
    );
  }, []);
  return <Container>{data.map(renderItem)}</Container>;
};

export default React.memo(IconList);
