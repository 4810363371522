import React from 'react';
import type { FC } from 'react';
import styled from 'styled-components';
import { mobile, tablet, useQuery } from 'styles/breakpoints';
import { DynamicImage } from 'components';

interface ImageSectionProps {
  image: string;
  imageMobile: string;
  alt: string;
  bgHex?: string;
}

const Container = styled.div<{ bgHex?: string }>`
  background-color: ${({ theme, bgHex }) =>
    bgHex ? bgHex : theme.colors.light0};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2.25rem 1rem;
  @media ${tablet} {
    padding: 1.25rem 1rem;
  }
`;

const ImageContainer = styled.div`
  line-height: 0;
  width: 100%;
  max-width: 71.25rem;
`;

const ImageWRapper = styled.div`
  max-width: 36.63419rem;
  width: 100%;
  margin: 0 auto;
`;
const ImageSection: FC<ImageSectionProps> = ({
  image,
  imageMobile,
  bgHex,
  alt,
}) => (
  <Container bgHex={bgHex}>
    <ImageContainer>
      <ImageWRapper>
        <DynamicImage src={image} mobile_src={imageMobile} alt={alt} />
      </ImageWRapper>
    </ImageContainer>
  </Container>
);

export default ImageSection;
