import React, { FC } from 'react';
import styled from 'styled-components';

import { Text } from 'components';
import { useSelector } from 'react-redux';
import { AppState } from 'state/types';
import { mobile, useQuery } from 'styles/breakpoints';

interface AnswerBasedImagesProps {
  title: string;
  questionKey: string;
  answerUrls: AnswerUrl;
  answerUrlsDesktop: AnswerUrl;
  label?: string;
}

interface AnswerUrl {
  [key: string]: string;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 3rem 0;
`;

const Title = styled(Text)`
  margin-bottom: 1rem;
  text-align: center;
  @media ${mobile} {
    max-width: 21.4375rem;
  }
`;

const Image = styled.img`
  width: 21.4375rem;
`;

const ImageDesktop = styled.img`
  flex: 1;
  width: 13.25rem;
`;

const Label = styled(Text)`
  margin-top: -0.25rem;
  text-align: center;
  margin-bottom: 3rem;
  @media ${mobile} {
    max-width: 21.4375rem;
    margin-bottom: 1rem;
  }
`;

const DesktopContainer = styled.div`
  max-width: 71.25rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
`;

const AnswerBasedImages: FC<AnswerBasedImagesProps> = ({
  title,
  questionKey,
  answerUrls,
  answerUrlsDesktop,
  label,
}) => {
  const { isMobile } = useQuery();
  const { quiz_answers } = useSelector((state: AppState) => state.user);

  const answers =
    quiz_answers[questionKey]
      ?.split('|')
      .filter((a: string) => a !== 'other') || [];

  const renderAnswerPictures = (answer: string, index: number) => (
    <Image src={answerUrls[answer]} key={index} />
  );

  const renderAnswerPicturesDesktop = (answer: string, index: number) => (
    <ImageDesktop src={answerUrlsDesktop[answer]} key={index} />
  );

  if (!answers.length) {
    return null;
  }

  return (
    <Container>
      <Title type={isMobile ? 'countS' : 'count'}>{title}</Title>
      {label ? <Label type={isMobile ? 'bodyM' : 'body'}>{label}</Label> : null}
      {isMobile ? (
        answers.map(renderAnswerPictures)
      ) : (
        <DesktopContainer>
          {answers.map(renderAnswerPicturesDesktop)}
        </DesktopContainer>
      )}
    </Container>
  );
};

export default AnswerBasedImages;
